<!-- @Author: Yu_Bo -->
<template>
  <div class='order_info zc_dialog_box'>
    <el-dialog :visible.sync="dialogVisible" width="920px" :close-on-click-modal='false'>
      <div slot="title">
        <div class="name">{{ details.order_status_text }}</div>
      </div>
      <!--  -->
      <div class="info_content">
        <div class="content_box" v-loading="loading" v-for="(item, index) in infoList" :key="index">
          <div class="title">{{ item.title }}</div>
          <div class="box_info">
            <div class="box_text" :class="ite.name=='备注：' ? 'w100' : 'w50'" v-for="(ite, idx) in item.childer" :key="idx">
              <div class="text">
                <span class="t_name">{{ ite.name }}</span>
                <span class="t_txt">{{ ite.text }}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
export default {
  components: {},
  props: {
    // title: {
    //   type: String,
    //   defaul: ''
    // }
  },
  data() {
    return {
      dialogVisible: false,
      id: null,
      loading: false,
      details: {}
    }
  },
  computed: {
    infoList() {
      return [
        {
          title: '商品信息',
          childer: [
            { name: '商品类别：', text: this.details.product_category_text },
            { name: '商品名称：', text: this.details.product_name },
            { name: '购买信息：', text: this.details.product_name },
          ]
        },
        {
          title: '付款人信息',
          childer: [
            { name: '付款人：', text: this.details?.member?.nickname },
            { name: '联系电话：', text: this.details?.member?.mobile },
          ]
        },
        {
          title: '订单信息',
          childer: [
            { name: '订单金额：', text: this.details.price },
            { name: '支付方式：', text: this.details.pay_type_text },
            { name: '订单编号：', text: this.details.order_num },
            { name: '流水编号：', text: this.details.trade_no },
            { name: '创建时间：', text: this.details.create_time },
            { name: '支付时间：', text: this.details.pay_time },
            { name: '备注：', text: this.details.remark },
          ]
        }
      ]
    }
  },
  watch: {},
  created() { },
  mounted() { },
  methods: {
    // 打开弹框
    openDialogBtn(type, id) {
      this.dialogVisible = true
      this.getDetail(id)
    },
    getDetail(id) {
      this.loading = true
      this.$personApi.orderConsumeDetails(id).then(res => {
        this.loading = false
        if (res.code == 1000) {
          this.details = res.result
        } else {

        }
      })
    }
  },
}
</script>

<style lang='scss' scoped>
.order_info {
  .info_content {
    width: 100%;
    min-height: 200px;
    padding: 0 20px;

    .content_box {
      width: 100%;
      margin-bottom: 20px;
      border-radius: 2px;
      border: 1px solid #DCDCDC;

      .title {
        width: 100%;
        padding: 0 20px;
        height: 42px;
        line-height: 42px;
        background: #F0F2F5;
        border-radius: 2px;
        font-size: 14px;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        color: #333333;
      }

      .box_info {
        width: 100%;
        padding-bottom: 20px;
        display: flex;
        flex-wrap: wrap;

        .box_text {
          padding-left: 30px;
          padding-top: 20px;

          .text {
            display: flex;

            .t_name {
              font-size: 14px;
              font-family: PingFangSC-Regular, PingFang SC;
              font-weight: 400;
              color: #666666;
            }

            .t_txt {
              font-size: 14px;
              font-family: PingFangSC-Regular, PingFang SC;
              font-weight: 400;
              color: #333333;
            }
          }
        }
        .w100{
          width: 100%;
        }
        .w50{
          width: 50%;
        }
      }
    }

    .content_box:last-child {
      margin-bottom: 0;
    }
  }
}</style>
