<!-- @Author: Yu_Bo -->
<template>
  <div class='organiz_manage zc_dialog_box'>
    <el-dialog :visible.sync="dialogVisible" width="548px" :close-on-click-modal='false'>
      <div slot="title">
        <div class="name">{{ title }}</div>
      </div>
      <div class="inv_main">
        <div class="main_text">
          <div class="left"><span>*</span>组织名称</div>
          <div class="right">
            <el-input v-model="form.name" placeholder="请输入组织名称" maxlength="12" show-word-limit>
            </el-input>
          </div>
        </div>
        <div class="main_text">
          <div class="left">描述</div>
          <div class="right">
            <el-input type="textarea" v-model="form.description" :rows="4" resize='none' placeholder="请输入描述"
              maxlength="100" show-word-limit></el-input>
          </div>
        </div>
      </div>
      <div slot="footer">
        <el-button class="btnBgColor_grey" size="small" @click="cancelBtn">取 消</el-button>
        <el-button class="btnBgColor_blue" size="small" type="primary" @click="affirmBtn" :loading="subLoading">确
          定</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
export default {
  components: {},
  props: {
    type: {
      type: [String, Number],
      defaul: 1
    },
  },
  data() {
    return {
      dialogVisible: false,
      subLoading: false,
      form: {
        name: '',
        description: '',
        parent_id: '',
      },
      id: ''
    }
  },
  computed: {
    title() {
      return this.type == 1 ? '新增' : '编辑'
    },
  },
  watch: {},
  created() { },
  mounted() { },
  methods: {
    // 打开弹框
    openDialogBtn(info) {
      this.dialogVisible = true
      this.form = info
    },
    // 取消
    cancelBtn() {
      this.dialogVisible = false
    },
    // 确定
    affirmBtn() {
      if (!this.form.name) {
        this.$errMsg('请输入组织名称')
        return
      }
      this.subLoading = true
      if (this.type == 1) {
        this.$personApi.organizationAdd(this.form).then(res => {
          this.subLoading = false
          if (res.code == 1000) {
            this.$succMsg(res.message)
            this.cancelBtn()
            this.$emit('refreshList')
          } else {
            this.$errMsg(res.message)
          }
        })
      }
      if (this.type == 2) {
        this.$personApi.organizationEdit(this.form, this.form.id).then(res => {
          this.subLoading = false
          if (res.code == 1000) {
            this.$succMsg(res.message)
            this.cancelBtn()
            this.$emit('refreshList')

          } else {
            this.$errMsg(res.message)
          }
        })
      }
    },
  },
}
</script>

<style lang='scss' scoped>
.organiz_manage {
  .inv_main {
    width: 100%;
    padding: 10px 30px 0;

    .main_text {
      width: 100%;
      margin-bottom: 20px;
      display: flex;
      justify-content: space-between;

      .left {
        width: 70px;
        line-height: 40px;
        text-align: right;
        font-size: 14px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #333333;

        span {
          padding-right: 5px;
          color: #E00C25;
        }
      }

      .right {
        width: 360px;

        .el-input {
          width: 100%;
        }
      }
    }

    .main_text:last-child {
      margin-bottom: 0;
    }
  }
}
</style>
