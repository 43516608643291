<!-- @Author: Yu_Bo -->
<template>
  <div class='person_main'>
    个人中心
    <!-- 订单信息 -->
    <order-info :title="order_title"></order-info>
    <!-- //消耗详情 -->
    <consumption-details></consumption-details>
    <!-- 邀请码 -->
    <invitation-code></invitation-code>
    <!-- 组织管理 -->
    <organiz-manage :type="organiz_type"></organiz-manage>
    <!-- 账号管理 -->
    <account-manage :type="account_type"></account-manage>
    <!-- 提示弹框 -->
    <point-out ref='dialogTip' type='account' @close='accountDelBtn' dialogTitle="提示" dialogWidth='380px' :iconShow='true' iconColor='#FF7432' borderRadius='10px' :cancelShow='true' :affirmShow='true'>
      <div class="zc_dialog_tip_content">
        <div class="text">删除后该账号不可登录，请确认是否删除？</div>
      </div>
    </point-out>
    <i class="el-icon-edit"></i>
    <i class="el-icon-share"></i>
    <i class="el-icon-delete"></i>
    <el-button class="btnBgColor_red" type="primary" icon="el-icon-search" @click="loginBtn">搜索</el-button>
  </div>
</template>

<script>
  import OrderInfo from './components/order_info/order_info.vue'//订单信息
  import ConsumptionDetails from './components/consumption_details/consumption_details.vue'//消耗详情
  import InvitationCode from './components/invitation_code/invitation_code.vue'//邀请码
  import OrganizManage from './components/organiz_manage/organiz_manage.vue'//组织管理
  import AccountManage from './components/account_manage/account_manage.vue'//账号管理
  import PointOut from '@/components/point_out/point_out.vue'//提示弹框

  export default {
    components: {OrderInfo,ConsumptionDetails,InvitationCode,OrganizManage,AccountManage,PointOut},
    props: {},
    data() {
      return {
        order_title:'订单已取消',
        account_type:2,
        organiz_type:2,
        Permission_type:2,
      }
    },
    computed: {},
    watch: {},
    created() {},
    mounted() {},
    methods: {
      //
      accountDelBtn(){
        console.log(123)
      },
      //
      loginBtn() {
        this.$router.push('/');
      }
    },
  }
</script>

<style lang='scss' scoped>
  .person_main {}
</style>
